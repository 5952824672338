

.shop-card {
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: transform 0.2s;
}

.shop-card:hover {
    transform: scale(1.05);
}

.some-text h4 {
    font-weight: 500;
}

.some-text h1 {
    font-size: 44px;
    color: var(--secondary-color);
}

.most-sellers {
    margin: 20px 0;
    text-align: center;
}

.sellers-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.seller-card {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
    width: 200px;
    text-align: center;
}



.product-card {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
    text-align: center;
    background-color: var(--background-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: transform 0.2s;
}

.product-card:hover {
    transform: scale(1.05);
}

.feature-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: auto;
    background-color: #fff;
    padding: 5px 5px 0 5px;
    border-radius: 5px;
    box-shadow: 0 15px 8px rgba(0, 0, 0, 0.1);
    margin-top: 2%;
    margin-bottom: 5%;
    padding: 5px;
}

.newsletter-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.newsletter-text {
    flex: 1;
    text-align: start;
    /* Takes up available space */
    padding-right: 20px;
    /* Add spacing between text and input area */
}

.newsletter-text h4 {
    font-size: 24px;
    font-weight: bold;
}

.newsletter-input {
    flex: 1;
    /* Takes up available space */
}

.input-group {
    display: flex;
    width: 80%;
}

.input-group input {
    border-radius: 0%;
    flex: 1;
    /* Takes up available space */
    height: 40px;
    border: none;
    border-bottom: 1px solid white;
    /* Example border for input */
    background-color: #fff;
    color: white;
}

.input-group-append button {
    height: 40px;
    width: 94px;
    border: none;
    background-color: var(--secondary-color);
    color: white;
    cursor: pointer;
    font-size: 14px;
    transition: filter 0.3s;
}

.input-group-append button:hover {
    filter: brightness(50%);
}
.val{
    font-size: 16px;
}
.latest-product-heading h1{
    color: #f57224;    
}

.latest-product-heading{
    text-align: center;
    padding-top: 3%; 
}