.footer {
    background-color: #333;
    color: #fff;
    padding: 3% 0;
}
/* .footer-logo{
    width: 70%;
} */
.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-logo img {
    max-width: 150px;
}

.footer-description {
    flex: 1;
    padding: 0 20px;
}

.footer-links ul {
    list-style: none;
    padding: 0;
}

.footer-links a {
    text-decoration: none;
    color: #fff;
    display: block;
    margin-bottom: 10px;
}

.footer-contact {
    flex: 1;
    padding: 0 20px;
}

.footer-follow {
    flex: 1;
    padding: 0 20px;
}

.follow-icons a {
    text-decoration: none;
    color: #fff;
    margin-right: 10px;
    font-size: 20px;
    padding-left: 3px;
    padding-right: 3px;
}