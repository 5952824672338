.cartpage{
    border: 2px solid rgb(228, 226, 226);
    width: 100%;
    min-height: auto;
    display: flex;
    padding:65px;
}
.lsbdata{
    padding-top:30px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    color: rgb(160, 157, 157);
}
.lsbar{
 
  width: 70%;
  border: 1px solid rgb(228, 226, 226);
  border-radius: 15px;

}
.lsbdata > .tabled{
    width: 90%;
}
.trh{
    align-items: center;
  margin-bottom:10px ;
}
.rsbar{
    padding-top: 30px;
   
    padding-top:30px;
    width: 23%;
    margin: 10px;
    margin-left: 40px;
}
.tabledata{
    
    width: 80%;
    display: flex;
    flex-direction: column;
    padding:16px;
    border: 1px dotted;
   background-color: rgb(251, 250, 250);
    
}
.btncart{
   width:100%;
   box-sizing: border-box;
}
.tcolor{
    color: #f57224;
}
.btncart .bcr {
    width: 100%;
    height: auto;
    border: none;
    color: #f57224;
    border: 2px solid #f57224;
    transition: 0.5s;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    background-color: white;
    cursor: pointer;
}
.bcr:hover{
   background-color: #f57224;
   color: rgb(56, 56, 56);
}
