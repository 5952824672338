/* Error Message Style */
.error {
    color: red;
    border: 1px solid red;
}
.back {
    position: fixed;
    left: 3%;
    text-align: center;
    top: 3%;
    width: 20px;
    height: 20px;
    font-weight: bolder;
    font-size: 16px;
    background: #0dd9f9;
    padding: 10px;
    color: white;
    transition: 0.3s ease-in;
    border-radius: 1000%;
}

.back:hover {
    background: transparent;
    color: #000000;
    border: 1px solid #000000;
}




.container h3 {
    color: #333;
    font-size: 24px;
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
}


.container input[type="text"],
.container input[type="email"],
.container input[type="number"],
.container input[type="file"] {
    width: 100%;
    padding: 12px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border 0.3s;
    background-color: #f9f9f9;
}

.container input[type="text"]:focus,
.container input[type="email"]:focus,
.container input[type="number"]:focus,
.container input[type="file"]:focus {
    border: 1px solid var(--secondary-color);
    background-color: #fff;
}

.container input[type="password"] {
    width: 50%;
    padding: 12px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border 0.3s;
    background-color: #f9f9f9;
}

.container input[type="password"]:focus {
    border: 1px solid var(--secondary-color);
    background-color: #fff;
}

.container select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border 0.3s;
    background-color: #f9f9f9;
}

.container select:focus {
    border: 1px solid var(--secondary-color);
    background-color: #fff;
}

.img-section img {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    border: 1px solid #000000;
    display: block;
    margin: 20px auto;
    object-fit: cover;
}

.container button {
    background-color: var(--secondary-color);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s;
    text-transform: uppercase;
}

.container button:hover {
    filter: brightness(80%);
}

.form-section {
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.SP{
    background-color: #fa9154;
    filter: opacity(1);
}