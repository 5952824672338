*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.upperdata{
  
    display: flex;
}
.lsdata{
    width: 70%;
  
}
.rsdata{
    width: 28%;
}
.innerdataf{
  
    padding: 30px;
   height:45vh;
}
.innerdataf > h3{
    margin-bottom: 10px;
    color: rgb(70, 70, 70);
}
.innerdataf > .ls{
    width: 45%;
    float: left;

}
.innerdataf > .rs{
    width: 45%;
    float: right;
}
.ls > label,.rs > label {
   display: block;
}
.ls > input,.rs > input{
    width: 100%;
    height: 40px;
    margin: 10px 0px;
}
.betweendetail{
    padding: 25px;
}
.rsdinnerd{
    background-color: rgb(239, 238, 238);
    width: 80%;
    margin: auto;
    padding: 20px;
    margin-top: 90px;
}
.rsdinnerd > p,span,h3{
    margin: 15px 0px ;

}
.pobtn{
    width:100%;
    box-sizing: border-box;
    padding: 10px;
    margin-top: 15px;
    border: none;
    color: #f57224;
    border: 2px solid #f57224;
    transition: 0.5s;
}
.pobtn:hover{
    background-color: #f57224;
   color: rgb(56, 56, 56);
   cursor: pointer;
}