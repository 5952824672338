/* Header container */
.header {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    background-color: #fff;
    padding: 5px 8%;
    color: white;
    border-top: 1px solid gainsboro;
    border-bottom:1px solid gainsboro;
}

/* Logo style */
.logo img {
    max-width: 120px;
}

/* Category dropdown button style */
/* .category-dropdown {
    position: relative;
}

.category-button {
    background: none;
    border: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.category-button svg {
    margin-left: 5px;
}

/* Category menu style */
/*.category-menu {
    list-style: none;
    padding: 0;
    position: absolute;
    background-color: #333;
    border: 1px solid #666;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
    top: 100%;
    left: 0;
    right: 0;
    display: none;
}

.category-menu li {
    padding: 10px;
    text-align: center;
}

.category-menu a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}

/* Show the category menu when the button is clicked */
/*.category-dropdown:hover .category-menu {
    display: block;
} */

.dropdown-button-container .dropdown-container {
    position: relative;
}

.scrollable-list {
    max-height: 200px;
    overflow-y: auto;
}

.dropdown-button-container .dropdown-button {
    background: var(--secondary-color);
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: all 0.3s;
}

.dropdown-button-container .dropdown-button svg {
    padding-left: 5px;
}

.dropdown-button-container .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    color: #333;
    background: var(--background-color);
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 200px;
    z-index: 1;
}

.dropdown-button-container input[type="text"] {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
}

.dropdown-button-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* .dropdown-button-container ul li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
} */

.dropdown-button-container ul hr {
    border: none;
    border-top: 1px solid #ccc;
    margin-bottom: 0px;
}

.category-link {
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    color: var(--text-color);
    font-weight: bold;
    border-radius: 4px;
    margin-right: 8px;
    background-color: var(--background-color);
    transition: background-color 0.3s;
    cursor: pointer;
}

.category-link:hover {
    background-color: var(--secondary-color);
    filter: saturate(80%);
}

.dropdown-button-container ul li:hover {
    background-color: var(--secondary-color);
    filter: saturate(80%);
}

.dropdown-button-container .description {
    position: absolute;
    top: 0;
    right: -837px;
    width: 816px;
    height: 227px;
    background-color: var(--background-color);
    padding: 10px;
    z-index: 2;
    /* display: none; */
}

/* .dropdown-button-container .description.active {
    display: block;
} */

.dropdown-button-container .dropdown-container .dropdown-button.open {
    letter-spacing: 0.08em;
    color: var(--secondary-color);
    background-color: transparent;
}

/* Search bar and cart icon styles */
.search-bar {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    margin: 0 10px;
    width: 250px;
}

.search-bar input {
    flex: 1;
    padding: 10px;
    border: none;
    outline: none;
    font-size: 16px;
    background: transparent;
}

.search-bar button {
    background-color: var(--secondary-color);
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 12px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
    transition: filter 0.3s;
}

.home a {
    text-decoration: none;
    color: var(--secondary-color);
}


.search-bar button:hover {
    filter: brightness(50%);
}

/* .cart-icon {
    position: relative;
    z-index: 1;
}

.cart-dropdown {
    position: absolute;
    background: #fff;
    color: #333;
    padding: 10px;
    border: 1px solid #666;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    top: 100%;
    right: 0;
    display: none;
}

.cart-icon:hover .cart-dropdown {
    display: block;
} */

.cart-icon {
    position: relative;
    cursor: pointer;
    margin-left: 20px;
}

/* .cart-icon:hover {
    transform: scale(1.05);
} */

.cart-badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: var(--secondary-color);
    color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.cart-dropdown {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: var(--background-color);
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 220px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
    /* display: none; */
    text-align: center;
    max-height: 250px;
    overflow-y: auto;

}

.cart-icon:hover .cart-dropdown {
    display: block;
}

.cart-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.cart-dropdown ul li {
    display: flex;
    color: var(--text-color);
    justify-content: space-between;
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
}

.cart-dropdown ul li button {
    background-color: var(--secondary-color);
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
}

.cart-dropdown ul li button:hover {
    background-color: var(--secondary-color);
}

.cart-dropdown a {
    text-decoration: none;
}

.cart-dropdown button {
    width: 100%;
    padding: 10px;
    background-color: var(--secondary-color);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.cart-dropdown button:hover {
    filter: brightness(70%);
}

.top-bar {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
}

.top-bar-button {
    background: none;
    text-align: center;
    text-decoration: none;
    color: black;
    border: none;
    background-color: var(--secondary-color);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.5rem 1.0rem;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.01em;
    min-width: 170px;
    border-radius: 0;
    white-space: normal;
    transition: all 0.3s;
    border: 1px solid #F57224;

}

.top-bar-button svg {
    padding-right: 5px;
}

.top-bar-button:hover {
    background-color: #fff;
    border: 1px solid #F57224;
    color: #F57224;
}
.navbar-filter{
    display: inline-flex;
}
.select{
    border-radius: 0%;
}
.textiya{
    justify-content: center;
    text-align: center;
}