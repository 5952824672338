.slick-slider {
    position: relative;
}

.slick-dots {
    position: absolute;
    bottom: 10px;
    list-style: none;
    text-align: center;
    display: flex;
    justify-content: center;
}

.slick-dots li {
    color: #979595;
}

.slick-dots li button {
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background: #333;
    cursor: pointer;
    transition: background 0.3s;
}

.slick-dots li.slick-active button {
    background: var(--secondary-color);
}

/* Style the images within the slider */
.slick-slide img {
    width: 100%;
    height: auto;
}