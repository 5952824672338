.title{
    text-decoration: underline #fff;
    color: #333;
    font-weight: 700;
    text-align: start;
    padding-top: 3px;
}
.product-category{
    text-decoration: underline #fff;
    color: gray;
    font-weight: 500;
    text-align: start;
}
.product-card{
    width: 100%;
}