.feature-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.feature-icon {
    margin-right: 10px;
}

.feature-details {
    align-items: center;
    /* Add this line to center the text with the icon */
    text-align: center;
}

.feature-details b {
    font-weight: bold;
    padding-bottom: 3px;
}

.feature-details p {
    color: #a19f9f;
}