.card-title{
    color: #333;
    text-decoration: aliceblue underline;
    margin-top: 5%;
}
.card-title:hover{
   text-decoration: none;
}
.card-text{
    font-size: 16px;
    color: #333;
    text-decoration: aliceblue underline;
}
.shop-card{
    margin-bottom: 10%;
}
.card-body:hover{
    text-decoration: white underline;
}